<script>
import Swal from "sweetalert2";

export default {
    name: 'decision-maker-card',

    props: {
        decisionMaker: {
            type: Object,
            required: true
        }
    },

    computed: {
        position: function () {
            return this.contact && this.decisionMaker.position
                ? this.decisionMaker.position.translation_key ? this.$t(this.decisionMaker.position.translation_key) : this.decisionMaker.position.name
                : null
        },

        letter: function () {
            return this.decisionMaker && this.decisionMaker.firstname ? this.decisionMaker.firstname[0] : '?'
        },

        flags: function () {
            return [
                {
                    flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: this.$t('intl.languages.en'),
                },
                {
                    flag: require("@/assets/images/flags/hungary.jpg"),
                    language: "hu",
                    title: this.$t('intl.languages.hu'),
                }
            ];
        },

        flag: function () {
            return this.flags.find(flag => flag.language === this.decisionMaker.locale);
        },
    },

    methods: {
        select: function () {
            this.$store.dispatch('decisionMaker/select', this.decisionMaker.id)
        },

        remove: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('decisionMaker/destroy', this.decisionMaker.id)
                }
            });
        },
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="dropdown float-end">
                <a
                    aria-haspopup="true"
                    class="text-muted dropdown-toggle font-size-16"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                >
                    <i class="bx bx-dots-horizontal-rounded"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item cursor-pointer" @click="select">{{ $t('base.edit').ucFirst() }}</a>
                    <a class="dropdown-item cursor-pointer" @click="remove">{{ $t('base.delete').ucFirst() }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div>
                    <img
                        v-if="decisionMaker.image"
                        :alt="decisionMaker.name"
                        :title="decisionMaker.name"
                        class="avatar-md rounded-circle img-thumbnail"
                        :src="decisionMaker.image"
                    />
                    <div v-else class="avatar-md">
                        <span class="avatar-title rounded-circle img-thumbnail text-white font-size-26" :class="{'bg-primary': decisionMaker.primary, 'bg-secondary': !decisionMaker.primary}"> {{ letter }} </span>
                    </div>
                </div>
                <div class="flex-1 ms-3">
                    <h5 class="font-size-16 mb-1">
                        <img
                            v-if="flag"
                            :title="flag.title"
                            alt="Header Language"
                            class="header-lang-img me-2"
                            :src="flag.flag"
                            width="18"
                        />
                        <span class="text-dark">{{ decisionMaker.name }}</span>
                    </h5>
                    <span class="badge badge-soft-success mb-0"
                    >{{ position }}</span
                    >
                </div>
            </div>
            <div class="mt-3 pt-1">
                <p class="text-muted mb-0">
                    <i
                        class="
                            mdi mdi-phone
                            font-size-15
                            align-middle
                            pe-2
                            text-primary
                          "
                    ></i>
                    {{ decisionMaker.phone }}
                </p>
                <p class="text-muted mb-0 mt-2">
                    <i
                        class="
                            mdi mdi-email
                            font-size-15
                            align-middle
                            pe-2
                            text-primary
                          "
                    ></i>
                    {{ decisionMaker.email }}
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
